// src/components/NewPost.js
import React, { useState, useCallback } from 'react'; // Ensure useCallback is imported
import ReactQuill from 'react-quill'; // Rich text editor
import 'react-quill/dist/quill.snow.css';
import { useDropzone } from 'react-dropzone'; // File upload
import { useParams, useNavigate } from 'react-router-dom'; // Use boardId from params
import axios from 'axios';

const NewPost = () => {
  const { boardId } = useParams(); // Get boardId from URL params
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [files, setFiles] = useState([]);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const navigate = useNavigate();

  // Correctly use useCallback and set up onDrop function
  const onDrop = useCallback((acceptedFiles) => {
    setFiles(acceptedFiles);
  }, []);

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('boardId', boardId); // Use the current board ID
    formData.append('userId', 1); // Example user ID, replace as needed
    formData.append('title', title);
    formData.append('content', content);
    files.forEach((file) => {
      formData.append('media', file);
    });

    try {
      await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/posts`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      setSuccess('Post created successfully!');
      navigate(`/board/${boardId}`); // Navigate back to the board after successful post creation
    } catch (error) {
      console.error('Error creating post:', error);
      setError('Failed to create post');
    }
  };

  return (
    <div className="p-6 bg-white rounded shadow-md">
      <h2 className="text-2xl font-bold mb-4">Create a New Post</h2>
      {error && <div className="text-red-500 mb-4">{error}</div>}
      {success && <div className="text-green-500 mb-4">{success}</div>}
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          placeholder="Title"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          className="w-full p-2 mb-4 border border-gray-300 rounded"
        />
        <ReactQuill
          value={content}
          onChange={setContent}
          className="mb-4"
        />
        <div {...getRootProps({ className: 'border-dashed border-2 p-4 mb-4' })}>
          <input {...getInputProps()} />
          <p>Drag & drop some files here, or click to select files</p>
        </div>
        <button type="submit" className="bg-blue-500 text-white p-2 rounded">
          Submit
        </button>
      </form>
    </div>
  );
};

export default NewPost;
