// src/components/Layout.js
import React, { useEffect, useState } from 'react';
import { fetchBoards } from '../api'; // Import the API call for fetching boards
import { Link } from 'react-router-dom';

const Layout = ({ children }) => {
  const [boards, setBoards] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    const loadBoards = async () => {
      try {
        const boards = await fetchBoards();
        if (boards && Array.isArray(boards)) {
          setBoards(boards);
        } else {
          setError('Unexpected response format');
        }
      } catch (error) {
        setError('Failed to load boards');
        console.error('Error fetching boards:', error);
      }
    };

    loadBoards();
  }, []);

  return (
    <div className="flex min-h-screen">
      {/* Sidebar */}
      <aside className="w-64 bg-gray-200 p-4 shadow-md">
        <h2 className="text-xl font-bold mb-4 text-blue-700">Boards</h2>
        {error ? (
          <div className="text-red-500">{error}</div>
        ) : (
          <ul className="space-y-2">
            {boards.map((board) => (
              <li key={board.id}>
                <Link to={`/board/${board.id}`} className="block text-blue-500 hover:underline">
                  {board.name}
                </Link>
              </li>
            ))}
          </ul>
        )}
      </aside>

      {/* Main Content */}
      <main className="flex-1 p-6 bg-gray-100">
        {children}
      </main>
    </div>
  );
};

export default Layout;
