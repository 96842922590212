import React, { useEffect, useState, useContext } from 'react';
import { useParams, Link } from 'react-router-dom';
import { fetchPostsByBoard } from '../api'; // Assume this function fetches posts for a given board
import { UserContext } from '../context/UserContext'; // User context to check if the user is logged in

const PostList = () => {
  const { boardId } = useParams();
  const [posts, setPosts] = useState([]);
  const [boardName, setBoardName] = useState('');
  const [error, setError] = useState(null);
  const { user } = useContext(UserContext);

  useEffect(() => {
    const loadPosts = async () => {
      try {
        const { posts, boardName } = await fetchPostsByBoard(boardId);
        setPosts(posts);
        setBoardName(boardName);
      } catch (error) {
        console.error('Error fetching posts:', error);
        setError('Failed to load posts');
      }
    };

    loadPosts();
  }, [boardId]);

  return (
    <div>
      <h1>{boardName}</h1>
      {error && <p>{error}</p>}
      {user && (
        <Link to={`/board/${boardId}/new`} className="button">
          글쓰기
        </Link>
      )}
      <ul>
        {posts.map((post) => (
          <li key={post.id}>
            <h2>{post.title}</h2>
            <p>{post.content}</p>
          </li>
        ))}
      </ul>
    </div>
  );
};
     
export default PostList;