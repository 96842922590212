// src/components/BoardList.js
import React, { useEffect, useState } from 'react';
import { fetchBoards } from '../api'; // Ensure this import is correct
import { Link } from 'react-router-dom';

const BoardList = () => {
  const [boards, setBoards] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    const loadBoards = async () => {
      try {
        const boards = await fetchBoards();
        if (boards && Array.isArray(boards)) {
          setBoards(boards);
        } else {
          setError('Unexpected response format');
        }
      } catch (error) {
        setError('Failed to load boards');
        console.error('Error fetching boards:', error);
      }
    };

    loadBoards();
  }, []);

  if (error) {
    return <div className="text-red-500">{error}</div>; // Display error message
  }

  if (boards.length === 0) {
    return <div className="text-gray-500">No boards available.</div>; // Show a message if no boards are found
  }

  return (
    <div className="min-h-screen bg-gray-100 p-4">
      <h1 className="text-3xl font-bold mb-6 text-center text-blue-700">Boards</h1>
      <ul className="space-y-4">
        {boards.map((board) => (
          <li key={board.id} className="bg-white p-4 rounded shadow-sm">
            <Link to={`/board/${board.id}`} className="text-blue-500 hover:underline">
              {board.name}
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default BoardList;
