import axios from 'axios';

// Base URL for the API, defaults to relative URL if not set
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || '';

const handleApiError = (error, action = 'perform the action') => {
    console.error(`Error ${action}:`, error.response ? error.response.data : error.message);
    throw new Error(`Failed to ${action}`);
  };

  // Fetch posts for a specific board
export const fetchPostsByBoard = async (boardId) => {
    try {
      const response = await axios.get(`${API_BASE_URL}/api/board/${boardId}`, {
        withCredentials: true,
      });
  
      if (response.status === 200 && response.data) {
        return response.data; // Return board and posts data
      } else {
        handleApiError(response, 'fetch posts for board');
      }
    } catch (error) {
      handleApiError(error, 'fetch posts for board');
    }
  };

// Fetch all boards
export const fetchBoards = async () => {
  try {
    const response = await axios.get(`${API_BASE_URL}/api/board`, {
      withCredentials: true, // Include cookies for session management
    });

    if (response.status === 200 && Array.isArray(response.data)) {
      return response.data; // Return the array of boards
    } else {
      handleApiError(response, 'fetch boards');
    }
  } catch (error) {
    handleApiError(error, 'fetch boards');
  }
};

// Fetch a specific post by post ID
export const fetchPostById = async (postId) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/api/posts/${postId}`, {
      withCredentials: true, // Include cookies for session management
    });

    if (response.status === 200 && response.data) {
      return response.data; // Return the specific post data
    } else {
      handleApiError(response, 'fetch post');
    }
  } catch (error) {
    handleApiError(error, 'fetch post');
  }
};

// Fetch comments for a specific post
export const fetchCommentsByPost = async (postId) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/api/comments?post_id=${postId}`, {
      withCredentials: true, // Include cookies for session management
    });

    if (response.status === 200 && Array.isArray(response.data)) {
      return response.data; // Return comments data
    } else {
      handleApiError(response, 'fetch comments');
    }
  } catch (error) {
    handleApiError(error, 'fetch comments');
  }
};

// Create a new post
export const createPost = async (postData) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/api/posts/create`, postData, {
      withCredentials: true, // Include cookies for session management
    });

    if (response.status === 201 && response.data) {
      return response.data; // Return the created post data
    } else {
      handleApiError(response, 'create post');
    }
  } catch (error) {
    handleApiError(error, 'create post');
  }
};

// Add a new comment to a post
export const addComment = async (commentData) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/api/comments/create`, commentData, {
      withCredentials: true, // Include cookies for session management
    });

    if (response.status === 201 && response.data) {
      return response.data; // Return the added comment data
    } else {
      handleApiError(response, 'add comment');
    }
  } catch (error) {
    handleApiError(error, 'add comment');
  }
};

// Delete a comment
export const deleteComment = async (commentId) => {
  try {
    const response = await axios.delete(`${API_BASE_URL}/api/comments/${commentId}`, {
      withCredentials: true, // Include cookies for session management
    });

    if (response.status === 200) {
      return response.data; // Return success data
    } else {
      handleApiError(response, 'delete comment');
    }
  } catch (error) {
    handleApiError(error, 'delete comment');
  }
};

// Register a new user
export const registerUser = async (userData) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/api/auth/register`, userData, {
      withCredentials: true, // Include cookies for session management
    });

    if (response.status === 201 && response.data) {
      return response.data; // Return registration success data
    } else {
      handleApiError(response, 'register user');
    }
  } catch (error) {
    handleApiError(error, 'register user');
  }
};

// Log in a user
export const loginUser = async (credentials) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/api/auth/login`, credentials, {
      withCredentials: true, // Include cookies for session management
    });

    if (response.status === 200 && response.data) {
      return response.data; // Return login success data
    } else {
      handleApiError(response, 'log in');
    }
  } catch (error) {
    handleApiError(error, 'log in');
  }
};
