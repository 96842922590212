// src/App.js
import React, { useContext } from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import Register from './components/Register';
import Login from './components/Login';
import BoardList from './components/BoardList';
import PostList from './components/PostList';
import PostDetail from './components/PostDetail';
import NewPost from './components/NewPost';
import Layout from './components/Layout';
import UserProvider, { UserContext } from './context/UserContext';


const Header = () => {
  const { user, logout } = useContext(UserContext);

  return (
    <header className="bg-blue-600 p-4 text-white shadow">
      <nav className="container mx-auto flex justify-between">
        <div className="font-bold text-lg">DisDay</div>
        <div className="space-x-4">
          {user ? (
            <>
              <button onClick={logout} className="hover:underline">
                로그아웃
              </button>
            </>
          ) : (
            <>
              <Link to="/register" className="hover:underline">
                회원가입
              </Link>
              <Link to="/login" className="hover:underline">
                로그인
              </Link>
            </>
          )}
        </div>
      </nav>
    </header>
  );
};

const App = () => {
  return (
    <UserProvider>
      <Router>
        <Header />
        <Layout>
          <Routes>
            <Route path="/register" element={<Register />} />
            <Route path="/login" element={<Login />} />
            <Route path="/boards" element={<BoardList />} />
            <Route path="/board/:boardId" element={<PostList />} />
            <Route path="/board/:boardId/new" element={<NewPost />} />
            <Route path="/post/:postId" element={<PostDetail />} />
          </Routes>
        </Layout>
      </Router>
    </UserProvider>
  );
};

export default App;
